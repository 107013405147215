export default [
  //Gudang
  //Penerimaan dari Pengiriman Cabang ke Toko
  {
    path: '/cabang/penerimaan',
    name: 'route-cabang-penerimaan',
    component: () => import('@/views/pages/cabang/gudang/penerimaan/List.vue'),
    meta: {
      authRequired: true,
      module: 'Penerimaan Toko',
      pageTitle: 'Penerimaan Toko',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/penerimaan/detail/:id',
    name: 'route-cabang-penerimaan-detail',
    component: () => import('@/views/pages/cabang/gudang/penerimaan/Detail.vue'),
    meta: {
      parent: '/cabang/penerimaan',
      module: 'Penerimaan Toko',
      authRequired: true,
      pageTitle: 'Penerimaan Toko',
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/penerimaan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  //Blok
  {
    path: '/cabang/blok',
    name: 'route-cabang-blok',
    component: () => import('@/views/pages/cabang/gudang/Blok.vue'),
    meta: {
      authRequired: true,
      module: 'Blok',
      pageTitle: 'Blok',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  //Palet
  {
    path: '/cabang/palet',
    name: 'route-cabang-palet',
    component: () => import('@/views/pages/cabang/gudang/Palet.vue'),
    meta: {
      authRequired: true,
      module: 'Palet',
      pageTitle: 'Palet',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  //Rak
  {
    path: '/cabang/rak',
    name: 'route-cabang-rak',
    component: () => import('@/views/pages/cabang/gudang/Rak.vue'),
    meta: {
      authRequired: true,
      module: 'Rak',
      pageTitle: 'Rak',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  //Laci
  {
    path: '/cabang/laci',
    name: 'route-cabang-laci',
    component: () => import('@/views/pages/cabang/gudang/Laci.vue'),
    meta: {
      authRequired: true,
      module: 'Laci',
      pageTitle: 'Laci',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  //Penyimpanan
  {
    path: '/cabang/penyimpanan',
    name: 'route-cabang-penyimpanan',
    component: () => import('@/views/pages/cabang/gudang/penyimpanan/List.vue'),
    meta: {
      authRequired: true,
      module: 'Storage Warehouse',
      pageTitle: 'Storage Warehouse',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/penyimpanan-sementara',
    name: 'route-cabang-penyimpanan-sementara',
    component: () => import('@/views/pages/cabang/gudang/penyimpanan/Sementara.vue'),
    meta: {
      authRequired: true,
      module: 'Belum ditempatkan',
      pageTitle: 'Belum ditempatkan',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/penempatan',
    name: 'route-cabang-penempatan',
    component: () => import('@/views/pages/cabang/gudang/penempatan/List.vue'),
    meta: {
      authRequired: true,
      module: 'Penempatan Toko',
      pageTitle: 'Riwayat Penyimpanan',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/retur-pusat',
    name: 'route-cabang-retur-pusat',
    component: () => import('@/views/pages/cabang/gudang/retur-pusat/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Retur Pusat',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/retur-pusat/detail/:id',
    name: 'route-cabang-retur-pusat-detail',
    component: () => import('@/views/pages/cabang/gudang/retur-pusat/Detail.vue'),
    meta: {
      parent: '/cabang/retur-pusat',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/retur-pusat',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/retur-pusat/edit/:id',
    name: 'route-cabang-retur-pusat-edit',
    component: () => import('@/views/pages/cabang/gudang/retur-pusat/Store.vue'),
    meta: {
      parent: '/cabang/retur-pusat',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/retur-pusat',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/retur-pusat/tambah',
    name: 'route-cabang-retur-pusat-tambah',
    component: () => import('@/views/pages/cabang/gudang/retur-pusat/Store.vue'),
    meta: {
      parent: '/cabang/retur-pusat',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/retur-pusat',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/stokopname',
    name: 'route-cabang-stokopname',
    component: () => import('@/views/pages/cabang/gudang/stok-opname/List.vue'),
    meta: {
      module: 'Stok Opname',
      pageTitle: 'Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,

        },
      ],
    },
  },
  {
    path: '/cabang/stokopname/:id',
    name: 'route-cabang-stokopname-rincian',
    component: () => import('@/views/pages/cabang/gudang/stok-opname/Rincian.vue'),
    meta: {
      parent: '/cabang/stokopname',
      module: 'Stok Opname',
      pageTitle: 'Rincian Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/stokopname',
        },
        {
          text: 'Rincian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/stokopname/detail/:id',
    name: 'route-cabang-stokopname-detail',
    component: () => import('@/views/pages/cabang/gudang/stok-opname/Store.vue'),
    meta: {
      parent: '/cabang/stokopname',
      module: 'Stok Opname',
      pageTitle: 'Detail Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/cabang/stokopname',

        },
        {
          text: 'List Barang',
          active: true,
        },
      ],
    },
  },{
    path: '/cabang/disposal-hilang',
    name: 'route-cabang-disposal-hilang',
    component: () => import('@/views/pages/cabang/gudang/disposal/DisposalHilang.vue'),
    meta: {
      authRequired: true,
      module: 'Hilang',
      pageTitle: 'Hilang',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/disposal-rusak',
    name: 'route-cabang-disposal-rusak',
    component: () => import('@/views/pages/cabang/gudang/disposal/DisposalRusak.vue'),
    meta: {
      authRequired: true,
      module: 'Rusak',
      pageTitle: 'Rusak',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cabang/pembelian',
    name: 'route-pembelian',
    component: () => import('@/views/pages/cabang/pembelian/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Pembelian Cabang',
      breadcrumb: [
        {
          text: 'Pembelian Cabang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/pembelian/detail/:id',
    name: 'route-pembelian-detail',
    component: () => import('@/views/pages/cabang/pembelian/Detail.vue'),
    meta: {
      parent: '/cabang/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian',
          to: '/cabang/pembelian',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/pembelian/edit/:id',
    name: 'route-pembelian-edit',
    component: () => import('@/views/pages/cabang/pembelian/Store.vue'),
    meta: {
      parent: '/cabang/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Cabang',
          to: '/cabang/pembelian',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/pembelian/bayar/:id',
    name: 'route-pembelian-bayar',
    component: () => import('@/views/pages/cabang/pembelian/Bayar.vue'),
    meta: {
      parent: '/cabang/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Cabang',
          to: '/cabang/pembelian',
        },
        {
          text: 'Input Pembayaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang/pembelian/add',
    name: 'route-pembelian-add',
    component: () => import('@/views/pages/cabang/pembelian/Store.vue'),
    meta: {
      parent: '/cabang/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Cabang',
          to: '/cabang/pembelian',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
