export default [

  {
    path: '/penyimpanan',
    name: 'penyimpanan',
    component: () => import('@/views/pages/penyimpanan/Index.vue'),
    meta: {
      module: 'Penyimpanan',
      pageTitle: 'Penyimpanan',
      authRequired: true,
    },
  },
  {
    path: '/penempatan',
    name: 'penempatan',
    component: () => import('@/views/pages/penyimpanan/PenempatanList.vue'),
    meta: {
      module: 'Penempatan',
      pageTitle: 'Penempatan',
      authRequired: true,
    },
  },
  {
    path: '/penyimpanan/add',
    name: 'penyimpanan-add',
    component: () => import('@/views/pages/penyimpanan/Penyimpanan.vue'),
    meta: {
      parent: '/penyimpanan',
      module: 'Penyimpanan',
      pageTitle: 'Penyimpanan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyimpanan',
          to: '/penyimpanan',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-opname',
    name: 'stock-opname',
    component: () => import('@/views/pages/stock-opname/List.vue'),
    meta: {
      module: 'Stok Opname',
      pageTitle: 'Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Stok Opname',
          active: true,

        },
      ],
    },
  },
  {
    path: '/stock-opname/:id',
    name: 'stock-opname-rincian',
    component: () => import('@/views/pages/stock-opname/Rincian.vue'),
    meta: {
      parent: '/stock-opname',
      module: 'Stok Opname',
      pageTitle: 'Rincian Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Stok Opname',
          to: '/stock-opname',
        },
        {
          text: 'Rincian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-opname/detail/:id',
    name: 'stock-opname-detail',
    component: () => import('@/views/pages/stock-opname/Store.vue'),
    meta: {
      parent: '/stock-opname',
      module: 'Stok Opname',
      pageTitle: 'Detail Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Stok Opname',
          to: '/stock-opname',

        },
        {
          text: 'List Barang',
          active: true,
        },
      ],
    },
  },
]
