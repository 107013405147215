export default [
  //JASA
  {
    path: '/stokout',
    name: 'route-stokout',
    component: () => import('@/views/pages/heykama/stokout/List.vue'),
    meta: {
      module: 'Stock Out',
      pageTitle: 'Stock Out',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/laporan2/add',
  //   name: 'route-laporan2-add',
  //   component: () => import('@/views/pages/laporan2/Store.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/laporan2/edit/:id',
  //   name: 'route-laporan2-edit',
  //   component: () => import('@/views/pages/laporan2/Edit.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
