export default [
  {
    path: '/retur-supplier',
    name: 'route-retur-supplier',
    component: () => import('@/views/pages/retur-supplier/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Retur Supplier',
      breadcrumb: [
        {
          text: 'Retur Supplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retur-supplier/detail/:id',
    name: 'route-retur-supplier-detail',
    component: () => import('@/views/pages/retur-supplier/Detail.vue'),
    meta: {
      parent: '/retur-supplier',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Retur Supplier',
          to: '/retur-supplier',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retur-supplier/edit/:id',
    name: 'route-retur-supplier-edit',
    component: () => import('@/views/pages/retur-supplier/Store.vue'),
    meta: {
      parent: '/retur-supplier',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Retur Supplier',
          to: '/retur-supplier',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retur-supplier/add',
    name: 'route-retur-supplier-add',
    component: () => import('@/views/pages/retur-supplier/Store.vue'),
    meta: {
      parent: '/retur-supplier',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Retur Supplier',
          to: '/retur-supplier',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
