export default [
  {
    path: '/pembelian',
    name: 'route-pembelian',
    component: () => import('@/views/pages/pembelian/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Pembelian Barang',
      breadcrumb: [
        {
          text: 'Pembelian Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembelian/detail/:id',
    name: 'route-pembelian-detail',
    component: () => import('@/views/pages/pembelian/Detail.vue'),
    meta: {
      parent: '/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian',
          to: '/pembelian',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembelian/edit/:id',
    name: 'route-pembelian-edit',
    component: () => import('@/views/pages/pembelian/Store.vue'),
    meta: {
      parent: '/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Barang',
          to: '/pembelian',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembelian/bayar/:id',
    name: 'route-pembelian-bayar',
    component: () => import('@/views/pages/pembelian/Bayar.vue'),
    meta: {
      parent: '/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Barang',
          to: '/pembelian',
        },
        {
          text: 'Input Pembayaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembelian/add',
    name: 'route-pembelian-add',
    component: () => import('@/views/pages/pembelian/Store.vue'),
    meta: {
      parent: '/pembelian',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Pembelian Barang',
          to: '/pembelian',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
