export default [
  {
    path: '/penerimaan-barang',
    name: 'penerimaan-barang',
    component: () => import('@/views/pages/penerimaan-barang/List.vue'),
    meta: {
      pageTitle: 'Penerimaan Barang',
      module: 'Penerimaan Barang',
      authRequired: true,
    },
  },
  {
    path: '/penerimaan-barang/view/:id',
    name: 'penerimaan-barang-view',
    component: () => import('@/views/pages/penerimaan-barang/Detail.vue'),
    meta: {
      parent: '/penerimaan-barang',
      module: 'Penerimaan Barang',
      authRequired: true,
      pageTitle: 'Info Penerimaan Barang',
      breadcrumb: [
        {
          text: 'Penerimaan Barang',
          to: '/penerimaan-barang',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/penerimaan-barang/add/:id',
    name: 'penerimaan-barang-add',
    component: () => import('@/views/pages/penerimaan-barang/Store.vue'),
    meta: {
      parent: '/penerimaan-barang',
      module: 'Penerimaan Barang',
      authRequired: true,
      pageTitle: 'Add',
      breadcrumb: [
        {
          text: 'Penerimaan Barang',
          to: '/penerimaan-barang',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/penerimaan-barang/edit/:id',
    name: 'penerimaan-barang-edit',
    component: () => import('@/views/pages/penerimaan-barang/Store.vue'),
    meta: {
      parent: '/penerimaan-barang',
      module: 'Penerimaan Barang',
      authRequired: true,
      pageTitle: 'Edit Penerimaan Barang',
      breadcrumb: [
        {
          text: 'Penerimaan Barang',
          to: '/penerimaan-barang',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
