export default [
  // ASSET ROUTE
  {
    path: '/asset',
    name: 'asset',
    component: () => import('@/views/pages/asset/barang/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Barang Aset', // for name menu
      breadcrumb: [
        {
          text: 'Barang Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/detail/:id',
    name: 'asset',
    component: () => import('@/views/pages/asset/barang/Detail.vue'),
    meta: {
      authRequired: true,
      parent: '/asset',
      breadcrumb: [
        {
          text: 'Barang Aset',
          to: '/asset',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/qr-code/:id',
    name: 'asset',
    component: () => import('@/views/pages/asset/barang/QRCode.vue'),
    meta: {
      authRequired: true,
      parent: '/asset',
      breadcrumb: [
        {
          text: 'Barang Aset',
          to: '/asset',
        },
        {
          text: 'QR Code',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/add',
    name: 'asset-add',
    component: () => import('@/views/pages/asset/barang/Store.vue'),
    meta: {
      parent: '/asset',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Barang Aset',
          to: '/asset',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset/edit/:id',
    name: 'asset-edit',
    component: () => import('@/views/pages/asset/barang/Store.vue'),
    meta: {
      parent: '/asset',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Barang Aset',
          to: '/asset',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // END
  // KATEGORI ASSET ROUTE
  {
    path: '/asset-kategori',
    name: 'asset-kategori',
    component: () => import('@/views/pages/asset/kategori/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Kategori Aset', // for name menu
      breadcrumb: [
        {
          text: 'Kategori Aset',
          active: true,
        },
      ],
    },
  },
  // END
  // BLOK
  {
    path: '/asset-blok',
    name: 'asset-blok',
    component: () => import('@/views/pages/asset/blok/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Blok Aset',
      breadcrumb: [
        {
          text: 'Blok Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-pengadaan',
    name: 'asset-pengadaan',
    component: () => import('@/views/pages/asset/pengadaan/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Procurement Aset',
      breadcrumb: [
        {
          text: 'Procurement Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-pengadaan/detail/:id',
    name: 'asset-pengadaan-detail',
    component: () => import('@/views/pages/asset/pengadaan/Detail.vue'),
    meta: {
      parent: '/asset-pengadaan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Procurement',
          to: '/asset-pengadaan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // PENYIMPANAN
  {
    path: '/asset-penyimpanan',
    name: 'asset-penyimpanan',
    component: () => import('@/views/pages/asset/penyimpanan/List.vue'),
    meta: {
      pageTitle: 'Penyimpanan Aset',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyimpanan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penyimpanan/add',
    name: 'asset-penyimpanan-add',
    component: () => import('@/views/pages/asset/penyimpanan/Store.vue'),
    meta: {
      parent: '/asset-penyimpanan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyimpanan Asset',
          to: '/asset-penyimpanan',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penyimpanan/add-empty',
    name: 'asset-penyimpanan-add-empty',
    component: () => import('@/views/pages/asset/penyimpanan/StoreEmpty.vue'),
    meta: {
      parent: '/asset-penyimpanan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyimpanan Asset',
          to: '/asset-penyimpanan',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penyimpanan/detail/:id',
    name: 'asset-penyimpanan-id',
    component: () => import('@/views/pages/asset/penyimpanan/Detail.vue'),
    meta: {
      parent: '/asset-penyimpanan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyimpanan Aset',
          to: '/asset-penyimpanan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // PEMINJAMAN
  {
    path: '/asset-peminjaman',
    component: () => import('@/views/pages/asset/peminjaman/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Peminjaman Aset',
      breadcrumb: [
        {
          text: 'Peminjaman Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-peminjaman/add',
    component: () => import('@/views/pages/asset/peminjaman/Store.vue'),
    meta: {
      authRequired: true,
      parent: '/asset-peminjaman',
      breadcrumb: [
        {
          text: 'Peminjaman Aset',
          to: '/asset-peminjaman',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-peminjaman/edit/:id',
    component: () => import('@/views/pages/asset/peminjaman/Store.vue'),
    meta: {
      authRequired: true,
      parent: '/asset-peminjaman',
      breadcrumb: [
        {
          text: 'Peminjaman Aset',
          to: '/asset-peminjaman',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-peminjaman/detail/:id',
    component: () => import('@/views/pages/asset/peminjaman/Detail.vue'),
    meta: {
      authRequired: true,
      parent: '/asset-peminjaman',
      breadcrumb: [
        {
          text: 'Peminjaman Aset',
          to: '/asset-peminjaman',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // PENGEMBALIAN
  {
    path: '/asset-pengembalian',
    component: () => import('@/views/pages/asset/pengembalian/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Pengembalian Aset',
      breadcrumb: [
        {
          text: 'Pengembalian Aset',
          active: true,
        },
      ],
    },
  },
  // PENJUALAN
  {
    path: '/asset-penjualan',
    component: () => import('@/views/pages/asset/penjualan/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Penjualan Aset',
      breadcrumb: [
        {
          text: 'Penjualan Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penjualan/detail/:id',
    component: () => import('@/views/pages/asset/penjualan/Detail.vue'),
    meta: {
      authRequired: true,
      parent: '/asset-penjualan',
      breadcrumb: [
        {
          text: 'Penjualan Aset',
          to: '/asset-penjualan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // MUTATION REQUEST
  {
    path: '/asset-mutasi-request',
    component: () => import('@/views/pages/asset/mutasi/request/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Request Mutasi Aset',
      breadcrumb: [
        {
          text: 'Request Mutasi Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-mutasi-request/detail/:id',
    component: () => import('@/views/pages/asset/mutasi/request/Detail.vue'),
    meta: {
      parent: '/asset-mutasi-request',
      authRequired: true,
      pageTitle: 'Request Mutasi Aset',
      breadcrumb: [
        {
          text: 'Request Mutasi Aset',
          to: '/asset-mutasi-request',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // MUTATION TERIMA
  {
    path: '/asset-mutasi-entry',
    component: () => import('@/views/pages/asset/mutasi/entry/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Entry Mutasi Aset',
      breadcrumb: [
        {
          text: 'Entry Mutasi Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-mutasi-entry/detail/:id',
    component: () => import('@/views/pages/asset/mutasi/entry/Detail.vue'),
    meta: {
      parent: '/asset-mutasi-entry',
      authRequired: true,
      pageTitle: 'Entry Mutasi Aset',
      breadcrumb: [
        {
          text: 'Entry Mutasi Aset',
          to: '/asset-mutasi-entry',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // AUDIT
  {
    path: '/asset-audit',
    component: () => import('@/views/pages/asset/audit/List.vue'),
    meta: {
      authRequired: true,
      pageTitle: 'Audit Aset',
      breadcrumb: [
        {
          text: 'Audit Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-audit/detail/:id',
    component: () => import('@/views/pages/asset/audit/Detail.vue'),
    meta: {
      parent: '/asset-audit',
      authRequired: true,
      pageTitle: 'Audit Aset',
      breadcrumb: [
        {
          text: 'Audit Aset',
          to: '/asset-audit',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // DISPOSAL RUSAK
  {
    path: '/asset-disposal/rusak',
    component: () => import('@/views/pages/asset/disposal/List.vue'),
    meta: {
      jenis: 2,
      authRequired: true,
      pageTitle: 'Aset Rusak',
      breadcrumb: [
        {
          text: 'Disposal Aset Rusak',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/rusak/add',
    component: () => import('@/views/pages/asset/disposal/Store.vue'),
    meta: {
      jenis: 2,
      parent: '/asset-disposal/rusak',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Disposal Aset Rusak',
          to: '/asset-disposal/rusak',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/rusak/detail/:id',
    component: () => import('@/views/pages/asset/disposal/Detail.vue'),
    meta: {
      jenis: 2,
      parent: '/asset-disposal/rusak',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Disposal Aset Rusak',
          to: '/asset-disposal/rusak',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/hilang',
    component: () => import('@/views/pages/asset/disposal/List.vue'),
    meta: {
      jenis: 1,
      authRequired: true,
      pageTitle: 'Disposal Aset Hilang',
      breadcrumb: [
        {
          text: 'Aset Hilang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/hilang/add',
    component: () => import('@/views/pages/asset/disposal/Store.vue'),
    meta: {
      jenis: 1,
      parent: '/asset-disposal/hilang',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Disposal Aset Hilang',
          to: '/asset-disposal/hilang',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/hilang/detail/:id',
    component: () => import('@/views/pages/asset/disposal/Detail.vue'),
    meta: {
      jenis: 1,
      parent: '/asset-disposal/hilang',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Disposal Aset Hilang',
          to: '/asset-disposal/hilang',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/perbaikan',
    component: () => import('@/views/pages/asset/perbaikan/List.vue'),
    meta: {
      jenis: 3,
      authRequired: true,
      pageTitle: 'Perbaikan Aset',
      breadcrumb: [
        {
          text: 'Perbaikan Aset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/perbaikan/add',
    component: () => import('@/views/pages/asset/perbaikan/Store.vue'),
    meta: {
      jenis: 3,
      parent: '/asset-disposal/perbaikan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Perbaikan Aset',
          to: '/asset-disposal/perbaikan',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-disposal/perbaikan/detail/:id',
    component: () => import('@/views/pages/asset/perbaikan/Detail.vue'),
    meta: {
      jenis: 3,
      parent: '/asset-disposal/perbaikan',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Perbaikan Aset',
          to: '/asset-disposal/perbaikan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-laporan',
    name: 'asset-laporan',
    component: () => import('@/views/pages/asset/laporan/Journal.vue'),
    meta: {
      api: '/cetak-laporan-asset',
      authRequired: true,
      component: 'ProfitAndLoss',
      pageTitle: 'Laporan Asset',
      breadcrumb: [
        {
          text: 'Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penyusutan',
    name: 'asset-penyusutan',
    component: () => import('@/views/pages/asset/penyusutan/List.vue'),
    meta: {
      // api: '/cetak-penyusutan-asset',
      authRequired: true,
      // component: 'ProfitAndLoss',
      pageTitle: 'Penyusutan Asset',
      breadcrumb: [
        {
          text: 'Asset',
          active: true,
        },
      ],
    },
  },
  {
    path: '/asset-penyusutan/detail/:id',
    name: 'asset-penyusutan-detail',
    component: () => import('@/views/pages/asset/penyusutan/Detail.vue'),
    meta: {
      parent: '/asset-penyusutan',
      pageTitle: 'Penyusutan Asset',
      authRequired: true,
      breadcrumb: [
        {
          text: 'Penyusutan',
          to: '/asset-penyusutan',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
]
